<template>
  <div>
    <v-layout column wrap>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h5 class="font-weight-light">LIST OF MEMBER</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3 class="mx-2">
            <v-select
              v-model="month_of"
              dense
              outlined
              label="Month Of"
              item-text="month_of"
              item-value="month_of"
              :items="month_of_items"
              :rules="rules.combobox_rule"
              @change="load_data"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 class="mx-2">
            <v-select
              v-model="type_of_member"
              dense
              outlined
              label="Type of Member"
              :items="['All','Individual','Business']"
              :rules="rules.combobox_rule"
              @change="load_data"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 class="mx-2">
            <v-select
              v-model="status"
              dense
              outlined
              label="Status"
              :items="['All','Paid','Unpaid']"
              :rules="rules.combobox_rule"
              @change="load_data"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-data-table dense
                      :headers="headers"
                      :items="data_items"
                      :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Members Data</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                class="mx-2"
                :append-icon="icons.mdiAccountSearch"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.date_registered }}
              </td>
              <td>
                {{ item.company_name }}
              </td>
              <td>
                {{ item.town }}
              </td>
              <td>
                {{ item.address }}
              </td>
              <td>
                {{ item.email_address }}
              </td>
              <td>
                {{ item.type_of_business }}
              </td>
              <td>
                {{ item.contact_no }}
              </td>
              <td>
                <v-chip color="success" outlined v-if="item.is_have_payment!=null">
                  PAID
                </v-chip>
                <v-chip color="error" outlined v-else>
                  UNPAID
                </v-chip>
              </td>
              <td class="text-center">
                <v-icon
                  class="mr-2"
                  color="info"
                  @click="view_branches(item)"
                >
                  {{ icons.mdiSourceBranch }}
                </v-icon>
              </td>
              <td class="text-center">
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="view_owners(item)"
                >
                  {{ icons.mdiAccountGroup }}
                </v-icon>
              </td>
              <td class="text-center">
                <v-icon
                  class="mr-2"
                  color="warning"
                  @click="edit_data(item)"
                >
                  {{ icons.mdiPencilBox }}
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
    <v-dialog v-model="is_view_business_owner" max-width="70%">
      <ListOfBusinessOwnerMember :key="key" :data_items="data_items2"></ListOfBusinessOwnerMember>
    </v-dialog>
    <v-dialog v-model="is_view_business_branches" max-width="70%">
      <ListOfBusinessBranchMember :key="key" :data_items="data_items3"></ListOfBusinessBranchMember>
    </v-dialog>
    <v-dialog v-model="is_edit" max-width="70%">
      <NewBusiness :key="key" :information_details="data" :for_update="true"
                   v-on:on_update_data="on_update_data"></NewBusiness>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiAccountGroup
  , mdiAccountSearch
  , mdiSourceBranch
  , mdiPencilBox
} from '@mdi/js'
import {mapActions, mapGetters} from "vuex";
import ListOfBusinessOwnerMember
  from "@/views/report_interface/Members/component/ListOfBusinessOwnerMember";
import ListOfBusinessBranchMember
  from "@/views/report_interface/Members/component/ListOfBusinessBranchMember";
import NewBusiness
  from "@/views/register_interface/NewBusiness";

export default {
  components: {
    ListOfBusinessOwnerMember,
    ListOfBusinessBranchMember,
    NewBusiness,
  },
  data() {
    return {
      month_of: '',
      key: 0,
      icons: {
        mdiAccountGroup,
        mdiAccountSearch,
        mdiSourceBranch,
        mdiPencilBox,
      },
      is_view_business_owner: false,
      is_view_business_branches: false,
      is_edit: false,
      data_items: [],
      data_items2: [],
      month_of_items: [],
      data_items3: [],
      data: {},
      type_of_member: 'All',
      search: '',
      status: 'All',
      headers: [
        {text: 'Date Registered', value: 'date_registered', sortable: true},
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Name', value: 'company_name', sortable: true},
        {text: 'Town', value: 'town', sortable: true},
        {text: 'Address', value: 'address', sortable: true},
        {text: 'Email', value: 'email_address', sortable: true},
        {text: 'Business Type', value: 'type_of_business', sortable: true},
        {text: 'Contact #', value: 'contact_no', sortable: true},
        {text: 'Status', align: 'center', value: 'edit', sortable: false},
        {text: 'View Branches', align: 'center', value: 'view_branches', sortable: false},
        {text: 'View Owners', align: 'center', value: 'view_owners', sortable: false},
        {text: 'Edit', align: 'center', value: 'edit', sortable: false},
      ],
    }
  },
  mounted() {
    this.list_of_all_months()
      .then(response => {
        this.month_of_items = response.data
        this.month_of_items.splice(0, 0, {
          id: -1,
          month_of: 'All',
        })
      })
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
  },
  methods: {
    ...mapActions('members_information', ['list_of_members']),
    ...mapActions('business_owners', ['get_list_of_business_owner']),
    ...mapActions('business_brenches', ['get_list_of_business_branches']),
    ...mapActions('transaction_months', ['list_of_all_months']),
    load_data() {
      const data = new FormData()
      data.append('is_individual', this.type_of_member === 'Individual' ? 1 : 0);
      data.append('is_business', this.type_of_member === 'Business' ? 1 : 0);
      data.append('month_of', this.month_of);
      data.append('status', this.status);
      this.list_of_members(data)
        .then(response => {
          this.data_items = response.data
        })
    },
    view_owners(item) {
      this.key++
      const data = new FormData()
      data.append('member_id', item.id);
      this.get_list_of_business_owner(data)
        .then(response => {
          this.is_view_business_owner = true
          this.data_items2 = response.data
        })
    },
    view_branches(item) {
      this.key++
      const data = new FormData()
      data.append('member_id', item.id);
      this.get_list_of_business_branches(data)
        .then(response => {
          this.is_view_business_branches = true
          this.data_items3 = response.data
        })
    },
    edit_data(item) {
      this.key++
      this.is_edit = true
      this.data = item
    },
    on_update_data() {
      this.key++
      this.is_edit = false
      this.data = {}
      this.load_data()
    }
  }
}
</script>
